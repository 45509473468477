<template>
  <v-menu
      open-on-hover
      transition="slide-y-transition"
      width="100vw">
    <template v-slot:activator="{ props }">
      <v-btn
          rounded="lg"
          append-icon="mdi-chevron-down"
          color="white"
          size="large"
          v-bind="props"
          variant="text">
        MENU
      </v-btn>
    </template>
    <nav class="menu-container">
      <v-row class="bg-primary border-bottom pb-5 text-center text-white">
        <v-col
            v-for="item in menuItens"
            :lg="numColunas"
            cols="12">
          <div class="lista-navegacao-container">
            <v-list
                class="bg-primary lista-menu"
                variant="text">
              <v-list-subheader class="mb-2">
                <span class="font-16 font-weight-medium text-white">
                  {{ item.titulo }}
                </span>
              </v-list-subheader>
              <v-divider/>
              <v-list-item
                  rounded="lg"
                  append-icon="mdi-chevron-right"
                  :href="navItem.url"
                  :target="navItem.url.includes('https://') ? '_blank' : ''"
                  v-for="(navItem, index) in item.itens"
                  :key="navItem.titulo"
                  :class="index !== item.itens?.length ? 'divider' : ''"
                  class="mx-2">
                <v-list-item-title
                    class="py-3">
                  <span class="mx-2">
                    {{ navItem.titulo }}
                  </span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
      </v-row>
    </nav>
  </v-menu>
</template>
<script lang="ts">
import type {PropType} from "vue";
import type {OpcaoMenu} from "~/pages/home-cliente.vue";

export default defineComponent({
  props: {
    menuItens: {
      type: Array as PropType<OpcaoMenu[]>,
      required: true,
    }
  },
  computed: {
    numColunas(): number {
      return 12 / this.menuItens.length;
    }
  }
})
</script>
<style scoped>
.border-bottom {
  border-bottom: 1px solid white;
}

.font-16 {
  font-size: 16px;
}

.lista-menu {
  padding: 0 !important;
  border-radius: 5px;
}

.lista-navegacao-container {
  text-align: start;
}

.menu-container {
  font-size: 24px;
  margin: 20px 0;
  border-bottom: 1px solid white;
}

.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
  padding-inline: 0 !important;
}

.v-expansion-panel {
  border-radius: 0 !important;
}

.v-btn--size-default {
  font-size: 12px !important;
  padding: 0;
}

:deep(.v-expansion-panel-text__wrapper) {
  padding: 8px 15px 16px !important;
}
</style>
